import './main.css';
import { Elm } from './Main.elm';
import registerServiceWorker from './registerServiceWorker';

var app = Elm.Main.init({
  node: document.getElementById('root')
});

var output = document.getElementById("output")

function copy() {
  var selection = getSelection();
  selection.removeAllRanges();

  var range = document.createRange();
  var copyText = output;
  range.selectNodeContents(copyText);
  selection.addRange(range);

  document.execCommand('copy');
  selection.removeAllRanges();
}

document.getElementById("copy_button").addEventListener("click", copy);

/*
var tweetButton = document.getElementById("tweet_button");

tweetButton.addEventListener("click", function(event) {
  tweetButton.href = 'https://twitter.com/intent/tweet?url=https%3A%2F%2Finsult45.com%2F&via=45insult&hashtags=insult45&text='
    + encodeURI(output.textContent)
}, false);

var faceButton = document.getElementById("face_button");

faceButton.addEventListener("click", function(event) {
  faceButton.href = 'https://www.facebook.com/dialog/share?app_id=287927352590084&display=popup&href=https%3A%2F%2Finsult45.com%2F&redirect_uri=https%3A%2F%2Finsult45.com%2F&quote='
    + encodeURI(output.textContent)
}, false);
*/

registerServiceWorker();
